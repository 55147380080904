.card.secret {
    text-align: center;
}

.card.secret.intro {
    padding-top: 1rem !important;
}

.card .nav-tabs {
    background: lightgray;
    position: sticky;
    top: 0;
    z-index: 1;
}

#photoshop .photoshop-images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}

.photoshop-images>div {
    cursor: pointer;
    width: 30%;
    display: flex;
    flex-direction: column;
    background-image: var(--background);
    background-color: lightgray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 1/1;
    border: white solid 2px;
}

.gap {
    flex-grow: 1;
}

#photoshop-label {
    background: -webkit-linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
}

#photoshop-name {
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1));
}

.card zero-md {
    text-align: left;
    margin: 1rem;
}

.card.secret .email {
    text-align: justify;
    width: calc(100% - 4rem);
    margin: auto;
    margin-bottom: 2rem;
}

.email br#n2 {
    content: "";
    margin: 2em;
    display: block;
    font-size: 0.5rem;
}