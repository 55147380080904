body {
    background-color: black;
    overflow: overlay;
}

* {
    scrollbar-color: rgba(0, 0, 0, 0.5) var(--content-color, magenta);
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-track {
    box-shadow: none !important;
    background-color: var(--content-color, magenta);
}

.lato.nav-link,
.lato {
    font-family: 'lato', sans-serif !important;
    font-size: 30px !important;
    color: #FFF !important;
    background: -webkit-linear-gradient(white, orange, white);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.lato:hover,
.lato.active:hover {
    background: -webkit-linear-gradient(orange, white, orange);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lato.active {
    background: -webkit-linear-gradient(#38495a, white, #38495a);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lato-title {
    font-family: 'lato', sans-serif !important;
    font-size: 50px;
    color: #FFF;
    background: -webkit-linear-gradient(white, orange);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sticky-overlay.super {
    z-index: 2000 !important;
}

.sticky-underlay {
    z-index: unset !important;
}

.sticky-underlay,
.sticky-overlay {
    z-index: 1;
    position: fixed;
    width: 100vw;
    height: min(100vh, 100dvh);
    pointer-events: none;
    overflow: hidden;
    padding: 0px !important;
}

.hyperlink {
    background-color: transparent;
    border: none;
    color: var(--bs-link-color);
    text-decoration: underline;
    cursor: pointer;
}

.hyperlink:hover {
    color: var(--bs-link-hover-color);
}