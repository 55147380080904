#achievement-window {
    position: sticky;
    height: 0px;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 100000000;
    pointer-events: none;
}

#achievement-window .screen {
    position: relative;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
}

.screen .achievement {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0px;
    border-radius: 100px;
    display: flex;
    opacity: 0;
    scale: 1;
    transition: opacity .50s, background-color 0s .50s;
}

.achievement .logo {
    --frame-size: 50px;
    --full-duration: 1.25s;
    --total-frames: 28;

    width: var(--frame-size);
    height: var(--frame-size);
    background-image: url(/public/images/achievement/logofull.webp);
    background-position-x: 0px;

}

.achievement .logo-container {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    border-radius: 100%;
    align-items: center;
    background-color: black;
}


.achievement .content {
    position: relative;
    width: 0px;
    color: #fff0;
    height: 60px;
    max-height: 60px;
    overflow: hidden;
}

.achievement .content :is(.first, .second, .third) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    top: 100%;
    position: absolute;
}

.achievement.notify {
    scale: 1;
    opacity: 1;
    background-color: gray;
}

.achievement.notify .logo-container {
    animation: fadeToGray .25s forwards;
}

.achievement.notify .logo {
    --from-frame: 0;
    --to-frame: 28;
    --delay: 0.75s;

    --steps: calc(var(--to-frame, 0) - var(--from-frame, 0));
    --frame-duration: calc(var(--full-duration) * var(--steps) / var(--total-frames));
    animation: dynamicSprite var(--frame-duration) steps(var(--steps)) forwards,
        logoborder var(--delay) forwards;
}

.achievement.notify .content {
    --text-duration: 2.5s;
    --base-delay: 1s;
    --base-duration: .5s;

    --base-offset: calc(var(--base-delay) + var(--base-duration));
    animation: fadeWidth var(--base-duration) var(--base-delay) forwards;
}

.achievement.notify .content .first {
    --start: 100%;
    --end: -100%;
    animation: fadeInWait var(--text-duration) calc(var(--base-offset)) forwards;
}

.achievement.notify .content .second {
    --start: 100%;
    --end: -100%;
    animation: fadeInWait var(--text-duration) calc(var(--text-duration) + var(--base-offset)) forwards;
}

.achievement.notify .content .third {
    --start: 100%;
    --end: 0%;
    animation: fadeInWait var(--text-duration) calc(var(--text-duration) * 2 + var(--base-offset)) forwards;
}

@keyframes fadeInWait {
    from {
        top: var(--start);
    }

    5% {
        top: 0%;
    }

    95% {
        top: 0%;
    }

    100% {
        top: var(--end);
    }
}

@keyframes fadeWidth {
    from {
        width: 0px;
        color: #fff0;
        padding: 0;
    }

    10% {
        padding: 0 10px;
    }

    60% {
        color: #fff0;
        text-indent: 1rem;
    }

    to {
        width: 250px;
        color: #ffff;
        text-indent: 0rem;
        padding: 0 10px;
    }
}

@keyframes logoborder {
    from {
        scale: 1.3;
    }

    to {
        scale: 1;
    }
}

@keyframes dynamicSprite {
    from {
        background-position-x: calc(-1 * var(--frame-size) * var(--from-frame));
    }

    to {
        background-position-x: calc(-1 * var(--frame-size) * var(--to-frame));
    }
}


@keyframes fadeToGray {
    from {
        opacity: 0;
        background-color: black;
    }

    50% {
        opacity: 1;
        background-color: black;
    }

    to {
        opacity: 1;
        background-color: darkgray;
    }

}


.achievement.close {
    opacity: 1;
    transition: none;
    background-color: gray;
    transform-origin: center;
    animation: fadeOut .5s .5s forwards;
}

.achievement.close .content {
    animation: fadeWidthOut .5s forwards;
}

.achievement.close .logo-container {
    background-color: darkgray;
}

.achievement.close .logo {
    background-position-x: 100%;
}

@keyframes fadeWidthOut {
    from {
        width: 250px;
        color: #ffff;
        text-indent: 0rem;
        padding: 0 10px;
    }

    40% {
        color: #fff0;
        text-indent: 1rem;
    }

    90% {
        padding: 0 10px;
    }

    to {
        width: 0px;
        color: #fff0;
        padding: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    to {
        opacity: 0;
        transform: translate(-50%, -50%) scale(.5);
    }
}

@media (pointer:coarse) {
    .achievement-modal {
        min-width: 90vw;
    }
}

.achievement-modal {
    --content-color: #313131;
    pointer-events: all;
    position: absolute;
    width: max(300px, 60vw);
    height: 60vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--content-color);
    display: flex;
    flex-direction: column;
    color: white;
    box-shadow: black 0px 0px 10px;
}

.achieved {
    width: 100%;
    border: black outset 2px;
    padding: .5rem;
    background-color: grey;
}

.achieved.inprogress {
    background-color: rgb(68, 68, 68);
}

.achieved>div {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.achieved .howto {
    margin-left: 1rem;
}

.achieved .date {
    align-self: flex-end;
    white-space: nowrap;
    font-size: .75rem;
}

.achieved .name {
    font-weight: bold;
    white-space: nowrap;
}

.footer-privacy {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem;
}

.achievement-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0px 1rem;
    overflow: auto;
}

.achieve-header {
    margin: .5rem;
}

.achieve-header .head {
    font-size: 2rem;
}

.achieve-header .info {
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

.achieve-header .exit {
    float: right;
}