div.face.about {
    --content-color: #34d0ff;
}
.face .about {
    background-color: var(--content-color);
    pointer-events: none;
    font-size: 2.5em;
    color: white;
    font-family: 'VT323', latin !important;
}

.about .info {
    margin: 0 12% 200px;
}

.info .tab {
    text-indent: calc(3vw + 1vw * var(--tabAmount, 0));
}

.about .self {
    width: 15%;
    height: 15%;
}

.sticky-overlay .score {
    position: absolute;
    bottom: calc(min(10vh, 90px) + 60px);
    font-weight: 400;
    font-size: 1.5rem;
    color: white;
    left: 60px;
    font-family: 'VT323', latin !important;
}

.bird {
    width: calc((375px - 15px) / 3);
    height: calc((375px - 15px) / 3);
    pointer-events: all;
    cursor: pointer;
    position: absolute;
}

.crosshair {
    background: url(/public/images/about/crosshair.svg);
    width: 120%;
    height: 120%;
    background-size: contain;
    position: absolute;
    display: none;
    transform: translate(-10%, -10%) !important;
    z-index: 2;
    aspect-ratio: 1/1;
}

.bird:hover .crosshair {
    display: block;
}

.bird.death {
    pointer-events: none !important;
    cursor: auto !important;
    animation: duckDeath 3s linear 0.5s forwards;
}

@keyframes duckDeath {
    to {
        transform: translateY(100vh);
    }
}

.bird div {
    transform: translate(40%, 40%);
}

.bird .left {
    -webkit-transform: scaleX(-1) translate(-40%, 50%);
    transform: scaleX(-1) translate(-40%, 50%);
}

.bird .up-left {
    -webkit-transform: scaleX(-1) translate(-40%, 50%);
    transform: scaleX(-1) translate(-40%, 50%);
}

.bird .hit {
    transform: translate(40%, 25%);
}

.bird .hit.left {
    -webkit-transform: scaleX(-1) translate(-30%, 25%);
    transform: scaleX(-1) translate(-30%, 25%);
}

.bird .hit.up-left {
    -webkit-transform: scaleX(-1) translate(-30%, 25%);
    transform: scaleX(-1) translate(-30%, 25%);
}

.bird .blue {
    width: calc((375px - 60px) / 9);
    height: calc((375px - 60px) / 9);
    aspect-ratio: 1/1;
    background: url(/public/images/about/sprite_sheet.webp);
    background-position-y: -120px;
    animation: blueDuck 0.4s steps(3) infinite;
}

.bird .blue.up-right {
    background-position-y: -155px;
}

.bird .blue.up {
    background-position-y: -195px;
}

.bird .blue.up-left {
    background-position-y: -155px;
}

.bird .blue.hit {
    width: 36px;
    height: 36px;
    animation: blueDuckHit 0.5s steps(2) 0.5s infinite;
    background-position-y: -231px;
    background-position-x: 0px;
}

@keyframes null {}

@keyframes blueDuck {
    from {
        background-position-x: 0;
    }

    to {
        background-position-x: calc(-1*(375px - 12px) / 3);
    }
}

@keyframes blueDuckHit {
    from {
        width: calc((375px - 80px) / 9);
        height: calc((375px - 80px) / 9 * 3/2);
        aspect-ratio: 2/3;
        background-position-x: -35px;
        -webkit-transform: scaleX(-1) translate(-35%, 25%);
        transform: scaleX(-1) translate(-35%, 25%);
    }

    to {
        width: calc((375px - 80px) / 9);
        height: calc((375px - 80px) / 9 * 3/2);
        aspect-ratio: 2/3;
        background-position-x: -35px;
        -webkit-transform: scaleX(3) translate(90%, 25%);
        transform: scaleX(3) translate(90%, 25%);
    }
}

.bird .green {
    width: calc((375px - 60px) / 9);
    aspect-ratio: 1/1;
    background: url(/public/images/about/sprite_sheet.webp);
    background-position-y: -120px;
    background-position-x: calc(-1* 375px / 3);
    animation: greenDuck 0.4s steps(3) infinite;
}

.bird .green.up-right {
    background-position-y: -155px;
}

.bird .green.up {
    background-position-y: -195px;
}

.bird .green.up-left {
    background-position-y: -155px;
}

.bird .green.hit {
    aspect-ratio: 9/10;
    animation: greenDuckHit 0.5s steps(2) 0.5s infinite;
    background-position-y: -228px;
    background-position-x: -130px;
}

@keyframes greenDuck {
    from {
        background-position-x: calc(-1* (375px + 15px) / 3);
    }

    to {
        background-position-x: calc(-1 * (375px + 10px) * 2 / 3 + 5px);
    }
}

@keyframes greenDuckHit {
    from {
        background-position-x: -166px;
        -webkit-transform: scaleX(-1) translate(-35%, 25%);
        transform: scaleX(-1) translate(-35%, 25%);
    }

    to {
        background-position-x: -166px;
        -webkit-transform: scaleX(3) translate(90%, 25%);
        transform: scaleX(3) translate(90%, 25%);
    }
}

.bird .brown {
    width: calc((375px - 60px) / 9);
    aspect-ratio: 1/1;
    background: url(/public/images/about/sprite_sheet.webp);
    background-position-y: -120px;
    background-position-x: calc(-1 * (375px + 12px) * 2 / 3);
    animation: brownDuck 0.4s steps(3) infinite;
}

.bird .brown.up-right {
    background-position-y: -155px;
}

.bird .brown.up {
    background-position-y: -195px;
}

.bird .brown.up-left {
    background-position-y: -155px;
}

.bird .brown.hit {
    aspect-ratio: 2/3;
    animation: brownDuckHit 0.5s steps(2) 0.5s infinite;
    background-position-y: -228px;
    background-position-x: calc(-1* (375px + 12px) *2/ 3);
}

@keyframes brownDuck {
    from {
        background-position-x: calc(-1 * (375px + 15px) *2 / 3);
    }

    to {
        background-position-x: calc(-1 * (375px + 5px));
    }
}

@keyframes brownDuckHit {
    from {
        background-position-x: -296px;
        -webkit-transform: scaleX(-1) translate(-35%, 25%);
        transform: scaleX(-1) translate(-35%, 25%);
    }

    to {
        background-position-x: -296px;
        -webkit-transform: scaleX(3) translate(90%, 25%);
        transform: scaleX(3) translate(90%, 25%);
    }
}

.grass {
    position: absolute;
    height: 90px;
    max-height: 10vh;
    background-color: aliceblue;
    width: 100vw;
    bottom: 0;
    z-index: 2;
    background: url(/public/images/about/grass.webp);
    background-repeat: repeat, no-repeat;
}

.dog {
    pointer-events: all;
    cursor: pointer;
    position: absolute;
    bottom: min(90px, 10vh);
    transform: translate(50%, 25%);
    -webkit-transform: translate(50%, 25%);
    margin-left: 30px;
    width: 60px;
    height: 60px;
    scale: 2;
    z-index: 0;
    aspect-ratio: 1/1;
    background: url(/public/images/about/sprite_sheet.webp);
    background-position-y: -60px;
    background-position-x: 180px;
    animation: heheDoggo 0.5s steps(2) infinite;
}

@keyframes heheDoggo {
    from {
        background-position-x: 180px;
    }

    to {
        background-position-x: 60px;
    }
}

.dog.retrieve {
    transform: translate(50%, 25%);
    aspect-ratio: 1/1;
    background-position-y: -60px;
    background-position-x: 60px;
    animation: dogRetriever 1s linear forwards;
}

@keyframes dogRetriever {
    0% {
        transform: translate(50%, 25%);
        background-position-x: 180px;
    }

    50% {
        transform: translate(50%, 100%);
        background-position-x: 180px;
    }

    51% {
        transform: translate(50%, 100%);
        background-position-x: 60px;
    }

    100% {
        transform: translate(50%, 25%);
        background-position-x: 60px;
    }
}

.dog.unbound {
    transform: translate(50%, 25%);
    aspect-ratio: 1/1;
    background-position-y: -60px;
    background-position-x: 60px;
    animation: dogRetrieverRev 1s linear forwards;
}

@keyframes dogRetrieverRev {
    0% {
        transform: translate(50%, 25%);
        background-position-x: 60px;
    }

    50% {
        transform: translate(50%, 100%);
        background-position-x: 60px;
    }

    51% {
        transform: translate(50%, 100%);
        background-position-x: 180px;
    }

    100% {
        transform: translate(50%, 25%);
        background-position-x: 180px;
    }
}