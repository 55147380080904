@font-face {
    font-family: "go3v2";
    src: url(/public/fonts/go3v2.ttf);
}

div.face.socials {
    --content-color: rgb(124, 72, 61);
}

.face .socials {
    cursor: none;
    padding: 0px !important;
    background-color: var(--content-color);
    background-image: url(/public/images/socials/game/woodplankbackground.webp);
    background-repeat: repeat;
    background-position: 50%;
    background-blend-mode: multiply;
}

.fnFont {
    fill: white;
    stroke: black;
    font-family: go3v2, sans-serif;
    letter-spacing: 0.15rem;
}

.mix {
    mix-blend-mode: multiply;
}

@keyframes rotSVG {
    from {
        rotate: calc(var(--rot, 0deg) + 360deg);
    }

    to {
        rotate: calc(var(--rot, 0deg) + 0deg);
    }
}

div.rainbowGradient {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: conic-gradient(from 270deg, #ff4800 10%, #dfd902 35%, #20dc68, #0092f4, #da54d8 72% 75%, #ff4800 95%);
}

div.gradient {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: conic-gradient(rgba(255, 47, 33, 0.5) 0% 5%, rgba(140, 9, 0, 0.5) 5% 10%, rgba(255, 47, 33, 0.5) 10% 15%, rgba(140, 9, 0, 0.5) 15% 20%, rgba(255, 47, 33, 0.5) 20% 25%, rgba(140, 9, 0, 0.5) 25% 30%, rgba(255, 47, 33, 0.5) 30% 35%, rgba(140, 9, 0, 0.5) 35% 40%, rgba(255, 47, 33, 0.5) 40% 45%, rgba(140, 9, 0, 0.5) 45% 50%, rgba(255, 47, 33, 0.5) 50% 55%, rgba(140, 9, 0, 0.5) 55% 60%, rgba(255, 47, 33, 0.5) 60% 65%, rgba(140, 9, 0, 0.5) 65% 70%, rgba(255, 47, 33, 0.5) 70% 75%, rgba(140, 9, 0, 0.5) 75% 80%, rgba(255, 47, 33, 0.5) 80% 85%, rgba(140, 9, 0, 0.5) 85% 90%, rgba(255, 47, 33, 0.5) 90% 95%, rgba(140, 9, 0, 0.5) 95% 100%);
}

.links {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 5%;
}

.option {
    --size: min(25vmax, 44vh, 44vw);
    width: var(--size);
    height: var(--size);
    transform: translate(-2%, 7.5%);
}

.option-group {
    overflow: hidden;
    transform: translate(2%, -7.5%);
    position: relative;
    width: var(--size);
    height: var(--size);
}

.option-group .circle .text {
    z-index: 1;
}

.option-group .circle>* {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
}

.option-group .circle {
    pointer-events: none;
    transform-origin: center;
    position: absolute;
    animation: rotSVG 20s forwards infinite linear;
    width: 100%;
    top: 50%;
    scale: 1.5;
}

.option-group .gif {
    cursor: pointer;
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.links.toGame {
    animation: disappear .125s linear forwards;
}

.links.fromGame {
    animation: reappear .125s linear forwards;
}

@keyframes disappear {
    from {
        opacity: 1;
        pointer-events: none;
    }

    to {
        opacity: 0;
        display: none;
        pointer-events: none;
    }
}


@keyframes reappear {
    from {
        opacity: 0;
        pointer-events: none;
    }

    50% {
        pointer-events: all;
    }

    to {
        opacity: 1;
        pointer-events: all;
    }
}