div.face.projects {
    --content-color: rgb(92, 92, 92);
}

.face .projects {
    background-color: var(--content-color);
}

.overlay {
    width: 100%;
    height: 100%;
    pointer-events: none;
    aspect-ratio: 1/1;
    background-size: cover;
    z-index: 20;
    filter: opacity(0.5%);
}

.tile div {
    pointer-events: none;
}

._modal {
    z-index: 20 !important;
    transition: background-color 1s;
}

._modal.hide {
    background-color: rgba(0, 0, 0, 0);
}

._modal.show {
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: all;
    cursor: pointer;
}

._modal .card {
    --content-color: rgb(216, 216, 216);
    position: relative;
    cursor: auto;
    width: 80vw;
    max-height: 80vh;
    overflow-y: scroll;
    padding: 0px !important;
    margin: auto;
    background-color: rgb(216, 216, 216);
    transition: top .7s;
}

.card .video {
    width: 100%;
    height: calc(55.4vw * 0.5625);
    background-color: gray;
    color: white;
}

._modal.hide .card {
    top: -80vh;
}

._modal.show .card {
    top: 14vh;
}

.toMinesweeper {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    animation: toMinesweeper 1s steps(34) 50ms forwards;
    background-size: cover;
    z-index: 20;
}

.projects .cardspot {
    width: min(15vh, 15vw);
    max-width: calc(50vw / 4 - 2%);
    display: inline-block;
    margin-right: 0.5%;
    border-radius: 5%;
    background-color: rgba(255, 255, 255, 0.2);
    aspect-ratio: 2.5/3.5;
}

@keyframes toMinesweeper {
    from {
        background-position-x: 0px
    }

    to {
        background-position-x: 100%
    }
}

.cell.in {
    background-color: rgb(212, 212, 212);
    animation: toCell 400ms 500ms forwards;
    border: 0px outset rgba(212, 212, 212, 0);
}

@keyframes toCell {
    from {
        border: 0px outset rgba(212, 212, 212, 0);
    }

    to {
        border: 9px outset rgba(219, 219, 219, 1);
    }
}

.cell {
    position: relative;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    background-color: rgb(212, 212, 212);
    border: 9px outset rgb(219, 219, 219);
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.mineContainer {
    align-items: center;
    width: calc(90vw - 30px);
    margin: auto;
    overflow-y: auto;
    overflow: visible;
    border: 10px inset #fff;
    margin-bottom: 15px;
}

.cell.revealed {
    border: 1px solid darkgray;

}

.mineContainer .row {
    margin: 0px;
}

.cell-tile {
    border: 9px outset rgb(219, 219, 219);
    padding: 0px;
    line-height: 0px;
    background-color: lightgray;
}

.cell-tile.toMine {
    border: 9px outset rgb(219, 219, 219);
    padding: 0px;
    animation: TiletoCell 1s forwards;
}

@keyframes TiletoCell {
    from {
        border: 9px outset rgba(219, 219, 219, 1);
        padding: 0px;
    }

    to {
        border: 0px outset rgba(212, 212, 212, 1);
        padding: 9px;
    }
}

.mineHeader {
    border: 10px inset #fff;
    margin: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.mineHeader .numbs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: scale(2.5);
    -webkit-transform: scale(2.5, 2.5);
}

.mineHeader .numbs.left {
    margin-left: 24px;
}

.mineHeader .numbs.right {
    margin-right: 24px;
}

.numb {
    background-image: url(/public/images/projects/minesweeper/minesweeperSpriteSheet.png);
    width: 12px;
    height: 24px;
    margin-top: 15px;
    background-position-x: 13px;
}

.numb.n0 {
    background-position-x: 13px;
}

.numb.n1 {
    background-position-x: 0px;
}

.numb.n2 {
    background-position-x: -14px;
}

.numb.n3 {
    background-position-x: -28px;
}

.numb.n4 {
    background-position-x: -42px;
}

.numb.n5 {
    background-position-x: -56px;
}

.numb.n6 {
    background-position-x: -70px;
}

.numb.n7 {
    background-position-x: -84px;
}

.numb.n8 {
    background-position-x: -98px;
}

.numb.n9 {
    background-position-x: 27px;
}

.mineHeader .button {
    background-image: url(/public/images/projects/minesweeper/minesweeperSpriteSheet.png);
    width: 24px;
    height: 24px;
    margin: 15px;
    -webkit-transform: scale(2.5, 2.5);
    transform: scale(2.5);
    background-position-x: -55px;
    background-position-y: -25px;
    box-sizing: content-box;
}

.mineHeader .button.lose {
    background-position-x: -109px;
}

.mineHeader .button.play {
    background-position-x: -1px;
}

.mineHeader .button.win {
    background-position-x: -82px;
}

.mineHeader .button-container {
    width: 60px;
    height: 100%;
    cursor: pointer;
}

.tile-center {
    text-align: -webkit-center;
}

.tile-container {
    width: var(--width);
    flex-wrap: wrap;
    justify-content: flex-start;
    display: flex;
}

.tile {
    display: inline-flex;
    background-color: grey;
    width: var(--correction, 320px);
    height: var(--correction, 320px);
    aspect-ratio: 1/1;
    background-size: contain !important;
    transition: box-shadow 0.1s, transform 0.1s;
    z-index: 1;
    margin: 5px;
    position: relative;
    transform-style: preserve-3d;
}

.tile-decal {
    box-shadow: 0px 0px 30px inset rgba(0, 0, 0, 0);
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
    position: absolute;
    transform: translateZ(0px);
    transition: 2s;
}

@media (pointer:coarse) {

    .tile.hover :is(.tile-title, .tile-date, .tile-createdIn) {
        color: rgba(255, 255, 255, 1);
        transform: translate(-50%, -50%) translateZ(80px);
        transition: color 0.5s, transform 0.5s;
    }

    .tile.hover .tile-decal {
        box-shadow: 0px 0px 30px inset rgba(0, 0, 0, 0.75);
        transform: translateZ(15px);
        top: -10%;
        left: -10%;
        width: 120%;
        height: 120%;
        border-radius: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        transition: 0.5s;
    }

    .tile.hover {
        z-index: 10;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
        cursor: pointer;
    }
}

@media (pointer:fine) {

    .tile:hover :is(.tile-title, .tile-date, .tile-createdIn) {
        color: rgba(255, 255, 255, 1);
        transform: translate(-50%, -50%) translateZ(80px);
        transition: color 0.5s, transform 0.5s;
    }

    .tile:hover .tile-decal {
        box-shadow: 0px 0px 30px inset rgba(0, 0, 0, 0.75);
        transform: translateZ(15px);
        top: -10%;
        left: -10%;
        width: 120%;
        height: 120%;
        border-radius: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        transition: 0.5s;
    }

    .tile:hover {
        z-index: 10;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
        cursor: pointer;
    }
}

.tile-title,
.tile-date,
.tile-createdIn {
    font-size: 150%;
    font-weight: 400;
    white-space: nowrap;
    pointer-events: none;
    transform: translate(-50%, -50%);
    position: absolute;
    color: rgba(255, 255, 255, 0);
    transition: 2s;
}

.tile-title {
    top: 25%;
    left: 50%;
}

.tile-date {
    top: 50%;
    left: 50%;
}

.tile-createdIn {
    top: 75%;
    left: 50%;
    font-size: 125%;
}

.flag {
    width: 100%;
    height: 100%;
    background-color: darkgray;
    background-size: contain !important;
}

.mineOutline {
    box-sizing: unset;
    align-items: center;
    width: 90vw;
    background-color: lightgray;
    margin: auto;
    border: 10px outset #fff;
}

.cell.revealed.mine {
    background-image: url(/public/images/projects/minesweeper/mine.png);
    background-size: contain;
    background-color: red;
}

.cell div {
    font-family: 'VT323', latin !important;
    line-height: calc(10vw - 30px);
    font-size: 6vw;
    pointer-events: none;
    place-content: center;
}

.cell div.c-1 {
    color: blue;
}

.cell div.c-2 {
    color: green;
}

.cell div.c-3 {
    color: red;
}

.cell div.c-4 {
    color: darkblue;
}

.cell div.c-5 {
    color: darkred;
}

.cell div.c-6 {
    color: darkcyan;
}

.cell div.c-7 {
    color: black;
}

.cell div.c-8 {
    color: gray;
}

.inlineImageButton {
    display: inline-block;
    margin: 0px 1vw;
}

.made-with a {
    margin-left: .3rem;
}

.made-with a:first-child {
    margin-left: 0rem;
}