@font-face {
    font-family: "Handjet";
    src: url(/public/fonts/Handjet.ttf);
}

div.face.intro {
    --content-color: rgb(2, 2, 18);
}

.face .intro {
    background-color: var(--content-color);
    padding: 0px !important;
    overflow: hidden;
    position: relative;
    max-width: 100vw;
    max-height: 100vh;
}

.intro :is(.welcome, .light-top, .light-bottom, .WASD) {
    transition: opacity .5s;
}

.intro.game-active :is(.welcome, .light-top, .light-bottom) {
    opacity: 0;
    pointer-events: none;
    cursor: unset;
}

#snake {
    position: sticky;
    top: 0;
    width: 100vw;
    height: min(100vh, 100dvh);
}

.welcome {
    font-family: Handjet;
    color: #FFF;
    position: absolute;
    font-style: normal;
    top: 53%;
    transform: translateY(-50%);
    left: 10%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: clamp(5px, 1vh, 16px);
    color: #FFF;
}

.welcome h3 {
    font-size: clamp(10px, 5vh, 40px);
    font-weight: 700;
    line-height: normal;
}

.welcome h1 {
    color: #FFF;
    font-size: clamp(30px, 15vh, 120px);
    font-weight: 700;
    line-height: normal;
}

.welcome .name {
    background: linear-gradient(180deg, #F5BF00 0%, #ED4B05 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: clamp(30px, 15vh, 120px);
    font-weight: 700;
    line-height: normal;
}

.welcome .cred {
    color: #f32222;
    font-size: clamp(14px, 7vh, 56px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.welcome .wave {
    display: inline-block;
    position: absolute;
    aspect-ratio: 1/1;
    height: clamp(12px, 6vh, 56px);
    margin-left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(/public/images/intro/wave.webp);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.light-bottom,
.light-top {
    filter: blur(5px);
    position: absolute;
    background-repeat: no-repeat;
}

.light-top {
    height: 332px;
    width: 833px;
    top: -2px;
    left: -2px;
    background-image: url(/public/images/intro/glow-top.svg);
}

.light-bottom {
    height: 345px;
    width: 765px;
    bottom: -2px;
    right: -2px;
    background-image: url(/public/images/intro/glow-bottom.svg);
}

.welcome .button-container {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    gap: 32px;
}

.intro .button {
    align-items: center;
    background: linear-gradient(180deg, var(--gradient-top, #A7A297) 0%, var(--gradient-bottom, #65593E) 100%);
    border: 2px solid var(--border-inner, #CDC0A2);
    border-radius: 8px;
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.2) inset, 0px 0px 0px 1px var(--border-outer, #65593E);
    color: var(--color, white);
    display: flex;
    flex-direction: column;
    font-size: clamp(15px, 2.875vh, 23px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    overflow: hidden;
    padding: 15px 24px;
    perspective: 25px;
    position: relative;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    transition: filter .25s;
}

.intro .button::after,
.intro .button::before {
    content: '';
    position: absolute;
    width: 40%;
    height: 15px;
    background-color: var(--decor-fill, #6f685a);
    border-radius: 3px;
    border: 2px solid var(--decor-border, #CDC0A2);
}

.intro .button::before {
    top: 0;
    transform: translateY(-50%) rotateX(-45deg);
}

.intro .button::after {
    bottom: 0;
    transform: translateY(50%) rotateX(45deg);
}

.intro .button:hover {
    filter: brightness(.6);
}

.intro .button.orange {
    --border-outer: #B65208;
    --border-inner: #773402;
    --gradient-top: #FCB601;
    --gradient-bottom: #AB6A09;
    --decor-fill: #773402;
    --decor-border: #B65208;
}

.intro .button.grey {
    --border-outer: #CDC0A2;
    --border-inner: #CDC0A2;
    --gradient-top: #A7A297;
    --gradient-bottom: #65593E;
    --decor-fill: #6f695a;
    --decor-border: #CDC0A2;
}


@media (pointer:coarse) {
    .intro.game-active .WASD {
        opacity: 1;
        pointer-events: all;
    }
}

.WASD>div,
.WASD {
    display: flex;
    gap: 5px;
}

.WASD {
    opacity: 0;
    pointer-events: none;
    cursor: unset;
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    flex-direction: column;
}

.WASD>div {
    flex-direction: row;
    justify-content: center;
}

.WASD .key {
    opacity: 0.5;
    --keySize: 15vmin;
    height: var(--keySize);
    width: var(--keySize);
    line-height: var(--keySize);
    font-size: calc(var(--keySize) / 2);
    text-align: center;
    vertical-align: center;
    border: 1px white solid;
}