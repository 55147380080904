body.builder,
body.builder :is(*, *::before, *::after) {
    box-sizing: border-box;
}

body.builder a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

body.builder button {
    background-color: transparent;
    color: inherit;
    border-width: 0;
    padding: 0;
    cursor: pointer;
}

body.builder figure {
    margin: 0;
}

body.builder :is(h1, h2, h3, h4, h5, h6) {
    color: unset;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}

body.builder p {
    margin: 0;
}

body.builder cite {
    font-style: normal;
}

body.builder fieldset {
    border-width: 0;
    padding: 0;
    margin: 0;
}

@font-face {
    font-family: "Avenir Next";
    src: url(/public/fonts/AvenirNextLTPro-Regular.otf);
}

@font-face {
    font-family: "Avenir Next Bold";
    src: url(/public/fonts/AvenirNextLTPro-Bold.otf);
}

@font-face {
    font-family: "Avenir Next Italic";
    src: url(/public/fonts/AvenirNextLTPro-It.otf);
}

@page {
    background-color: var(--background);
    margin: 0in;
    print-color-adjust: exact;
}

@page :first {
    margin-top: 0in;
}

@media print {
    html {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        background-color: var(--background);
    }

    body.builder :is(.no-print, .no-print *) {
        display: none !important;
    }

    body.builder .autopagebreak {
        clear: both;
        display: inline-block;
        width: 100%;
        page-break-after: auto;
    }

    body.builder .pagebreak {
        clear: both;
        page-break-after: always;
    }

    body.builder .pagebreak-spacing {
        margin-top: 1vw;
    }

    body.builder * {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    body.builder {
        background-color: var(--background) !important;
    }

    body.builder .resume {
        box-shadow: none !important;
        scale: 1 !important;
    }

    body.builder .resume-container {
        height: unset !important;
    }
}

body.builder .fixed-overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 20;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 15%;
}

body.builder .resume {
    box-shadow: 0 0 15px black;
    background-color: var(--background);
    scale: 0.7;
    transform-origin: top center;
}

.page-outline {
    outline: 1px magenta solid;
    width: 100%;
    aspect-ratio: 8.5/11;
}

:root {
    --content-color: white;
    --background: #333333;
    --buttons: #007EA7;
    --buttonsAlpha: #007da738;
    --header: #F7B423;
    --OffWhite: #F7F4F3;
    --lightBlue: #277691;
    font-family: "Avenir Next";
    --OffRed: rgb(211, 83, 83);
    --OffOrange: rgb(211, 133, 60);
    --OffYellow: rgb(209, 189, 6);
    --OffGreen: rgb(32, 180, 44);
    --OffBlue: rgb(9, 166, 172);
    --OffPurple: rgb(195, 39, 209);
}

body.builder {
    background-color: unset;
    overflow: overlay;
}

:root #printer {
    --background: #fff;
    --buttons: #575757;
    --buttonsAlpha: #007da738;
    --OffWhite: #000000;
    --lightBlue: #277691;
}

body.builder#printer .logo {
    border-radius: 0px;
    filter: grayscale(1);
}

body.builder#printer header {
    background-color: transparent;
}

body.builder#printer footer {
    background-color: transparent;
}

body.builder#printer :is(section a, #alt a, .more-info a) {
    color: black;
    font-family: "Avenir Next Bold";
}


body.builder#printer .links svg {
    stroke: #fff;
    fill: #fff;
}

body.builder .links h5::after {
    content: '';
    top: 50%;
    right: .5vw;
    transform: translateY(-50%);
    --size: 2vw;
    width: var(--size);
    height: var(--size);
    background-image: var(--icon);
    position: absolute;
}

body.builder .links .email {
    --icon: url(/public/images/builder/mail-white.svg);
}

body.builder .links .globe {
    --icon: url(/public/images/builder/globe-white.svg);
}

body.builder#printer #alt {
    background-color: transparent;
}


body.builder#printer .header {
    background-color: #fff;
}

#printer .advanced,
#printer .beginner,
#printer .intermediate,
body.builder#printer .novice {
    --visual-color: #fff;
    color: #fff;
}

#printer header h1,
body.builder#printer header h4 {
    color: #000 !important;
}

body.builder#printer h1.more-info {
    color: #000 !important;
}

body.builder#printer header h5 {
    color: #fff !important;
}

body.builder strong {
    font-family: "Avenir Next Bold";
}

body.builder body {
    background-color: var(--background);
    padding: 0px;
    margin: 0px;
}

body.builder header {
    background-color: var(--header);
    width: 100%;
    display: flex;
    justify-content: space-between;
}

body.builder .logo {
    width: 8vw;
    height: 8vw;
    background-image: url(/public/images/nav/logosprite.webp);
    background-size: cover;
    background-position-x: 100%;
    background-color: var(--background);
    border-radius: 100%;
    margin: 1vw;
}

body.builder header h1 {
    font-size: 4vw;
}

body.builder header h4 {
    font-size: 2vw;
}

body.builder header .title {
    align-self: center;
    padding-bottom: 1vw;
    color: var(--background);
}

body.builder .header-container {
    display: flex;
}

body.builder header .links {
    align-self: center;
    text-align: end;
    overflow: unset;
    display: unset;
    flex-wrap: unset;
    justify-content: unset;
    gap: unset;
}


body.builder header h5 {
    position: relative;
    background-color: var(--buttons);
    color: var(--OffWhite);
    margin: .75vw;
    margin-left: auto;
    padding: 0.5vw;
    padding-left: 1vw;
    width: fit-content;
    border-radius: 1vw;
    padding-right: 3.5vw;
    font-size: 1.5vw;
}

body.builder .links .icon {
    padding: 0.5vw;
    position: absolute;
    top: 0px;
    width: 2.5vw;
    height: 100%;
    right: 0px;
    margin-right: 0.5vw;
}

body.builder main div {
    margin: auto;
}

body.builder main {
    margin-bottom: 1vw;
}

body.builder #alt {
    background-color: rgba(0, 0, 0, 0.25);
    height: 5vw;
    color: var(--OffWhite);
}

body.builder #alt h1 {
    padding-top: 1vw;
    font-size: 2vw;
    text-align: center;
}

body.builder #alt h1 a {
    font-family: "Avenir Next Bold";
}

body.builder .header {
    position: relative;
    background-color: var(--buttons);
    width: -moz-fit-content;
    width: fit-content;
    transform: translateY(1vw);
    margin-bottom: 1vw;
    padding: 0.5vw 3vw;
    display: flex;
    overflow: hidden;
    --size: 5vw;
}

body.builder .header:before {
    content: '';
    position: relative;
    background-image: var(--icon, unset);
    width: var(--size);
    height: var(--size);
    transform: translateY(5%);
    background-size: cover;
}

body.builder#printer .header.checklist {
    --icon: url(/public/images/builder/checklist-black.svg);
}

body.builder .header.checklist {
    --icon: url(/public/images/builder/checklist-white.svg);
}

body.builder#printer .header.diploma {
    --icon: url(/public/images/builder/diploma-black.svg);
}

body.builder .header.diploma {
    --icon: url(/public/images/builder/diploma-white.svg);
}

body.builder#printer .header.gear::before {
    --size: 4vw;
    transform: translateY(15%);
    --icon: url(/public/images/builder/gear-black.svg);
}

body.builder .header.gear::before {
    --size: 4vw;
    transform: translateY(15%);
    --icon: url(/public/images/builder/gear-white.svg);
}

body.builder .header .icon {
    color: var(--OffWhite);
    height: 3.5vw;
    aspect-ratio: 1/1;
}

body.builder .header h1 {
    align-self: center;
    padding: 0.3vw 0;
    padding-left: 1vw;
    color: var(--OffWhite);
    font-size: 4vw;
    text-transform: uppercase;
}

body.builder .cut-outs div {
    position: absolute;
    height: 50%;
    border-radius: 100%;
    background-color: var(--background);
    aspect-ratio: 1/1;
}

body.builder .cut-outs div:nth-child(1) {
    left: 0px;
    bottom: 0px;
    transform: translate(-50%, 50%);
}

body.builder .cut-outs div:nth-child(2) {
    right: 0px;
    top: 0px;
    transform: translate(50%, -50%);
}

body.builder .cut-outs div:nth-child(3) {
    right: 0px;
    bottom: 0px;
    transform: translate(50%, 50%);
}

body.builder .cut-outs div:nth-child(4) {
    left: 0px;
    top: 0px;
    transform: translate(-50%, -50%);
}

body.builder svg {
    stroke: var(--OffWhite);
    fill: var(--OffWhite);
}

body.builder .info-container.full {
    width: 85vw;
    transform: translateY(1.5vw);
    margin-bottom: 1.5vw;
}

body.builder .info-container {
    position: relative;
    border: var(--OffWhite) 0.25vw solid;
    width: fit-content;
    border-radius: 2vw;
    color: var(--OffWhite);
}

body.builder .row li {
    list-style: none;
    display: block;
}

body.builder ul.row {
    padding: 0;
}

body.builder .info-container .title {
    position: absolute;
    top: 0px;
    transform: translate(-0.25vw, -50%);
    padding: 0.5vw;
    padding-left: 3.5vw;
    padding-right: 1vw;
    font-size: 2vw;
    color: var(--OffWhite);
    background-color: var(--background);
}

body.builder .info-container .title::before {
    content: '';
    --size: 3vw;
    position: absolute;
    width: var(--size);
    height: var(--size);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-image: var(--icon, transparent);
    background-repeat: no-repeat;
    background-position: center;
}

#printer .title.work {
    --icon: url(/public/images/builder/work-black.svg);
}

.title.work {
    --icon: url(/public/images/builder/work-white.svg);
}

#printer .title.work {
    --icon: url(/public/images/builder/work-black.svg);
}

#printer .title.university {
    --icon: url(/public/images/builder/university-black.svg);
}

.title.university {
    --icon: url(/public/images/builder/university-white.svg);
}

#printer .title.personal {
    --icon: url(/public/images/builder/personal-black.svg);
}

.title.personal {
    --icon: url(/public/images/builder/personal-white.svg);
}

#printer .title.building {
    --icon: url(/public/images/builder/building-black.svg);
}

.title.building {
    --icon: url(/public/images/builder/building-white.svg);
}

#printer .title.coding {
    --icon: url(/public/images/builder/coding-black.svg);
}

.title.coding {
    --icon: url(/public/images/builder/coding-white.svg);
}

#printer .title.software {
    --icon: url(/public/images/builder/software-black.svg);
}

.title.software {
    --icon: url(/public/images/builder/software-white.svg);
}

body.builder em {
    font-family: "Avenir Next Italic";
}

body.builder .info-container .content {
    margin: 2vw;
    margin-top: 2.5vw;
    font-size: 1.5vw;
    gap: .25vw;
    display: flex;
    flex-wrap: wrap;
}

body.builder .info-container .icon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 3vw;
}

body.builder .info-container .content {}

body.builder .info-container .content.row {
    flex-direction: row;
}

body.builder .info-container .content.col {
    flex-direction: column;
}

body.builder .content :is(.advanced, .intermediate, .beginner, .novice) {
    width: unset;
    max-width: unset;
    padding: 0.5vw;
    padding-right: 1vw;
    padding-left: 2.5vw;
    border-radius: 1vw;
    position: relative;
    background-color: var(--color);
    margin: 0.125vw;
}

body.builder .content .visual {
    position: absolute;
    top: 0.7vw;
    left: 0.5vw;
    width: 1.5vw;
    height: 1.5vw;
    padding: 0px;
    margin: 0px;
    transform: rotate(45deg);
    border: 0.15vw var(--visual-color) solid;
    overflow: hidden;
}

body.builder .visual>div {
    width: 2vw;
    height: 1.5vw;
    background-color: var(--visual-color);
}

body.builder .advanced .visual>div {}

body.builder .intermediate .visual>div {
    transform: rotate(-45deg) translateY(10%);
}

body.builder .beginner .visual>div {
    transform: rotate(-45deg) translateY(40%);
}

body.builder .novice .visual>div {
    transform: rotate(-45deg) translateY(70%);
}

body.builder .skills-container {
    transform: translateY(1vw);
    margin-bottom: 1vw;
    width: 85vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

body.builder .skills-container>.info {
    margin-top: 0px;
    display: flex;
    align-self: flex-start;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 2.2vw;
    width: 100%;
}

body.builder#printer .content .container {
    --color: grey !important;
}

body.builder#printer .content .container .category {
    --background: white;
}

body.builder#printer .legend {
    --header: transparent;
    --background: black;
}

body.builder#printer :is(.novice, .intermediate, .advanced, .beginner) {
    color: black;
    --visual-color: black;
    --color: transparent;
}

body.builder .resume.viewhidden .legend.no-print {
    display: block;
}

body.builder .legend {
    background-color: var(--header);
    border-radius: 2vw;
    margin-top: 0px;
    padding: 0.5vw;
    width: 20vw;
    font-size: 1.5vw;
    border: var(--OffWhite) 0.25vw solid;
    color: var(--background);
}

body.builder .legend-side {
    gap: 3vw;
    margin-left: 2.5vw;
    margin-top: 0;
    max-width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

body.builder .info .info-container {
    width: 100%;
}

body.builder .content .container {
    border-bottom: var(--color) 0.25vw solid;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px;
    gap: .125vw;
    padding-bottom: 1vw;
    margin-bottom: 1vw;
    position: relative;
    flex-shrink: 0;
    width: unset;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    flex-wrap: wrap;
}

body.builder .content .container .category {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 55%);
    padding: 0px 0.5vw;
    white-space: nowrap;
    background-color: var(--background);
    font-size: 1.5vw;
}

body.builder h1.more-info {
    margin: 1vw auto;
    margin-top: 0.5vw;
    font-size: 2vw;
    text-align: center;
    color: var(--header);
}

body.builder h1.more-info a {
    color: var(--OffWhite);
}

body.builder .info-container .content.block {
    display: block;
}

body.builder footer {
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: var(--OffWhite);
}

body.builder footer h1 {
    font-size: 1.5vw;
    text-align: center;
}

body.builder ul {
    margin-top: 0px;
    padding-left: 2.5vw;
    margin-bottom: 0px;
}

body.builder li {
    padding-left: 0.25vw;
}

body.builder section {
    position: relative;
    margin-bottom: 0.5vw;
    padding: 0.25vw;
    border: 0.125vw solid transparent;
}

body.builder section header {
    background-color: transparent;
    border-bottom: var(--OffWhite) 0.2vw solid;
    margin-bottom: 0.5vw;
}

body.builder section.present {
    background-color: var(--buttonsAlpha);
    padding: 0.5vw;
    border-radius: 1vw;
    border: 0.25vw solid var(--OffBlue);
}

body.builder .minors {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 0.5vw
}

body.builder section a {
    color: var(--header);
}

body.builder section header em {
    white-space: nowrap;
}

body.builder .sticky-overlay * {
    pointer-events: all;
    width: fit-content;
}

body.builder .sticky-overlay {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

body.builder .sticky-overlay>div {
    max-width: 15vw;
    display: flex;
    flex-direction: column;
}

body.builder .sticky-overlay>div:last-child {
    margin-right: 20px;
}

body.builder .sticky-overlay input[type='number'] {
    width: 2.25rem;
}

body.builder :is(.advanced, .intermediate, .beginner, .novice)::before {
    content: '';
    position: absolute;
    transform: translate(-110%, -50%);
    top: 50%;
    --size: 2vw;
    width: var(--size);
    height: var(--size);
    background-image: var(--icon, transparent);
}

body.builder .legend .advanced,
body.builder#printer .advanced {
    --icon: url(/public/images/builder/advanced-black.svg);
}

body.builder .legend .intermediate,
body.builder#printer .intermediate {
    --icon: url(/public/images/builder/intermediate-black.svg);
}

body.builder .legend .beginner,
body.builder#printer .beginner {
    --icon: url(/public/images/builder/beginner-black.svg);
}

body.builder .legend .novice,
body.builder#printer .novice {
    --icon: url(/public/images/builder/novice-black.svg);
}

body.builder .advanced {
    --icon: url(/public/images/builder/advanced-white.svg);
}

body.builder .intermediate {
    --icon: url(/public/images/builder/intermediate-white.svg);
}

body.builder .beginner {
    --icon: url(/public/images/builder/beginner-white.svg);
}

body.builder .novice {
    --icon: url(/public/images/builder/novice-white.svg);
}

body.builder main>.info-container.full+.info-container.full {
    margin-top: 2.5vw;
}

body.builder .resume .no-print {
    display: none;
}

body.builder .resume.viewhidden .no-print {
    display: inherit;
    opacity: .5;
    outline: 5px magenta solid;
}

body.builder .controls>* {
    pointer-events: all;
}

body.builder .controls {
    flex-direction: row;
    gap: 1vw;
    font-size: 1.75vw;
    pointer-events: none;
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    display: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
}

body.builder *:hover>.controls {
    display: flex;
}

.controls :is(.vis, .hid, .exp) {
    display: none;
}

.controls>div {
    background-image: var(--icon-button, blue);
    height: 5vmin;
    width: 5vmin;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.present .controls>#focus {
    --icon-button: url(/public/images/builder/focus-active.svg);
}

.controls #visibility.hid {
    --icon-button: url(/public/images/builder/eye-active.svg);
}

.controls #visibility.vis {
    --icon-button: url(/public/images/builder/eye.svg);
}

.controls>#focus {
    --icon-button: url(/public/images/builder/focus.svg);
}

.full .content .controls .exp {
    display: block;
}

.no-print>.controls .hid {
    display: block;
}

:not(.no-print)>.controls .vis {
    display: block;
}

body.builder *:has(.controls) {
    position: relative;
}

body.builder {
    background-color: rgb(150, 147, 178);
}

body.builder .sticky-header {
    z-index: 10000;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(76, 76, 85);
    color: white;
    flex-wrap: wrap;
    padding: .5vw 1.5vw;
    gap: 1vw;
}

body.builder .icon-button:hover {
    opacity: .7;
}

body.builder .icon-button {
    transition: opacity .25s;
    background-image: var(--icon, transparent);
    height: 2rem;
    width: 2rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

body.builder .icon-button.pages {
    --icon: url(/public/images/builder/onepage.svg);
}

body.builder .icon-button.pages.true {
    --icon: url(/public/images/builder/onepage-active.svg);
}

body.builder .icon-button.edit {
    background-size: 1.75rem;
    --icon: url(/public/images/builder/editing.svg);
}

body.builder .icon-button.edit.true {
    --icon: url(/public/images/builder/editing-active.svg);
}

body.builder .icon-button.hidden {
    --icon: url(/public/images/builder/eye.svg);
}

body.builder .icon-button.hidden.true {
    --icon: url(/public/images/builder/eye-active.svg);
}

body.builder .icon-button.modes {
    --icon: url(/public/images/builder/printer.svg);
}

body.builder .icon-button.modes.true {
    --icon: url(/public/images/builder/printer-active.svg);
}

body.builder .icon-button.print {
    --icon: url(/public/images/builder/print.svg);
}