:root {
    --frame: 16;
}

.pointer {
    cursor: pointer;
}


.disable {
    pointer-events: none;
}

.enable {
    pointer-events: all !important;
}

.greyBG {
    background-color: rgb(49, 49, 49) !important;
    color: white !important;
}

.brand {
    margin: 0px !important;
    padding: 0px !important;
    margin-left: 15px !important;
}

.hamButton {
    filter: invert();
    margin-right: 30px;
    z-index: 2;
    border-color: black !important;
    border-width: 2px !important;
}

.hamButton .navbar-toggler-icon {
    fill: blue;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active .navbar-toggler-icon {
    outline: none;
    box-shadow: none;
}

nav {
    opacity: 1;
    transition: opacity .25s;
}

nav.faded {
    opacity: 0 !important;
}

nav.faded * {
    pointer-events: none !important;
}

.navShadow {
    width: 100vw;
    z-index: 0;
    background: -webkit-linear-gradient(rgba(1, 1, 1, 0.9), rgba(1, 1, 1, 0));
    height: max(100px, min(18vh, 120px));
    top: 0;
    position: absolute;
    pointer-events: none;
}

.z-2 {
    z-index: 2;
}

@media (max-width:991px) {
    .checkpoint-container {
        width: 50vw;
        height: 100%;
        left: max(90px, min(17vh, 121px)) !important;
        align-content: center;
    }
}

@media (min-width:992px) {
    .checkpoint-container {
        width: 80vw;
        height: calc(max(90px, min(17vh, 121px)) / 2);
        align-content: center;
    }

    .navImage {
        margin-top: 15px;
    }
}

@media (max-height:542px) and (min-width:991px) {
    .checkpoint-container {
        align-content: unset !important;
        width: fit-content;
        top: 120px !important;
        left: 0vh !important;
        width: 90px;
    }
}

a.nav-link {
    outline: none !important;
    background: unset;
}

.checkpoint-container {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    top: 0;
    z-index: 20;
    left: min(19vh, 135px);
}

.checkpoint {
    aspect-ratio: 1/1;
    width: 30px;
    height: 30px;
    position: relative;
}

.checkpoint div {
    aspect-ratio: 1/1;
    height: 40%;
    width: 40%;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    border: solid rgba(175, 175, 175, 0.2) 2px;
    position: absolute;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.checkpoint.correct div {
    border: solid rgb(9, 255, 0) 2px;
    box-shadow: 0 0 5px #fff, 0 0 12px rgb(255, 255, 255), 0 0 15px rgb(0, 150, 20), 0 0 35px rgb(46, 201, 54), 0 0 11px inset rgb(0, 255, 85);
    background-color: rgb(218, 253, 218);
}

.checkpoint.complete div {
    border: solid rgb(248, 223, 1) 2px;
    box-shadow: 0 0 5px #fff, 0 0 12px rgb(255, 255, 255), 0 0 15px rgb(150, 85, 0), 0 0 35px rgb(240, 236, 28), 0 0 11px inset rgb(223, 197, 51);
    background-color: rgb(253, 253, 253);
}

.checkpoint.wrong {
    pointer-events: all !important;
    cursor: pointer;
}

.checkpoint.wrong div {
    border: solid rgb(243, 34, 34) 2px;
    box-shadow: 0 0 5px rgb(236, 104, 104), 0 0 12px rgb(230, 144, 144), 0 0 15px rgb(150, 0, 0), 0 0 35px rgb(240, 28, 28), 0 0 11px inset rgb(238, 94, 94);
    background-color: rgb(255, 0, 0);
}

.checkpoint.wrong:hover div {
    opacity: 0.5;
}


.navImage {
    height: max(90px, min(17vh, 121px));
    aspect-ratio: 1/1;
    background-position-x: calc(var(--frame, 16) / 16 * 100%);
    background-size: cover;
    background-repeat: no-repeat;
}

.navImage:hover {
    animation: iconAni 1s steps(16) 500ms infinite alternate-reverse;
}

@keyframes iconAni {
    to {
        background-position-x: 0%;
    }

    from {
        background-position-x: 100%;
    }
}

.navpadding {
    padding-top: min(121px, max(80px, 12vh));
}

.navbar {
    max-height: 121px;
}

.container-fluid .mobile-only {
    display: none;
}

.navbutton {
    border: unset;
    margin: 5px;
}