div.face.skills {
    --content-color: rgb(36, 36, 36);
}

.face .skills {
    padding: 0;
    background-color: var(--content-color);
}

.flex-catagory.filtered .segment {
    top: var(--sorted-height);
}

.skills .segment {
    transition: top 0.3s ease-in-out;
    top: var(--base-height);
    position: absolute;
    width: 100%;
    color: #0077ff;
    padding: 5px;
}

.skills .flex-catagory h1 {
    color: #0077ff;
}

.skills .progress {
    background-color: transparent;
    overflow: visible;
    z-index: 0;
}

.progress-bar.clear {
    background-color: white;
    height: 50%;
    transform: translateY(50%);
}

.skills .progress-bar {
    overflow: visible;
    background-color: transparent;
}

.hilt {
    width: 92px;
    height: 16px;
}

.skills .progress-bar .light {
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0 0 5px #fff, 0 0 12px #fff, 0 0 15px #00f, 0 0 35px #00f;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    transition: width 2s;
}

.focus:is(.ani-back, .ani-bottom, .ani-right, .ani-left) .skills .progress-bar .light.in {
    animation: light-in 1s ease forwards;
}

@keyframes light-in {
    from {
        width: 20%;
    }

    to {
        width: 100%;
    }
}

.prior .skills .progress-bar .light.in {
    animation: light-out 0.7s linear forwards;
}

@keyframes light-out {
    from {
        width: 100%;
    }

    to {
        width: 0%;
    }
}

.skip .skills .progress-bar .light.in {
    width: 0% !important;
}

.skills .flex-catagory {
    background-color: rgb(26, 26, 26);
    border-radius: 30px;
    border: #fff 1px solid;
    box-shadow: 0 0 5px #fff, 0 0 15px #00f;
}

@media (max-width: 1200px) {
    .skills .flex-container {
        width: 100%;
    }

    .skills .flex-catagory {
        width: 80%;
        margin-bottom: 5%;
        margin-left: 10%;
    }
}

@media (min-width: 1201px) {
    .skills .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .skills .flex-catagory {
        margin-bottom: 5%;
        min-width: 40%;
    }
}

.segment .title {
    color: rgb(69, 71, 197);
    display: inline;
}

.segment .percent {
    font-size: 1rem;
    float: right;
    padding-top: 12px;
}

.segment .barContainer {
    height: 10px;
    background-color: #636363;
    border-radius: 10px;
}

.segment .bar {
    height: 100%;
    background-color: #f79234;
    border-radius: 10px;
}

.skills-filter {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    pointer-events: all;
    width: calc(2rem + 2vw);
    margin: 18px;
}

.skills-filter svg {
    pointer-events: none;
    transition: fill 0.5s, stroke 0.5s;
}

.filtered .skills-filter svg {
    fill: rgb(129, 129, 129) !important;
    stroke: rgb(129, 129, 129) !important;
}

.skills-filter:hover svg {
    opacity: 0.5;
}

.relative {
    position: relative;
}

.linkedinApproved {
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
    transform: translateX(100%);
}

.topPercent {
    font-size: .9rem;
    display: none;
    position: absolute;
    width: 80px;
    max-height: 20px;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}

.segment:hover .topPercent {
    display: block;
}

.starfield-container {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 0;
}

.starfield {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}