.cube-container {
    overflow: hidden;
    perspective: 1800px;
    position: absolute;
    width: 100%;
    height: 100%;
    animation: fadeIn 1s ease forwards;
    background-color: black;
}

:root {
    --cube-rot-speed: 600ms;
    --cube-rot-speed-back: 900ms;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.face {
    overflow: overlay;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
}

.face>div {
    min-height: 100% !important;
    padding: 20px;
}

/*Rotate Up*/

.focus.ani-top {
    transform-origin: center bottom;
    animation: focus-top var(--cube-rot-speed) ease forwards;
}

.prior.ani-top {
    transform-origin: center top;
    animation: prior-top var(--cube-rot-speed) ease forwards;
}

@keyframes focus-top {
    0% {
        z-index: -1;
        transform: translateY(-100%) rotate3d(1, 0, 0, 90deg);
    }

    50% {
        z-index: 0;
    }

    100% {
        z-index: 0;
        transform: translateY(0%) rotate3d(0, 0, 0, 0deg);
    }
}

@keyframes prior-top {
    0% {
        z-index: 0;
        transform: translateY(0%) rotate3d(0, 0, 0, 0deg);
    }

    50% {
        z-index: -1;
    }

    100% {
        z-index: -1;
        transform: translateY(100%)rotate3d(-1, 0, 0, 90deg);
    }
}

/*Rotate Left*/

.focus.ani-left {
    transform-origin: right center;
    animation: focus-left var(--cube-rot-speed) ease forwards;
}

.prior.ani-left {
    transform-origin: left center;
    animation: prior-left var(--cube-rot-speed) ease forwards;
}

@keyframes focus-left {
    0% {
        z-index: -1;
        transform: translateX(-100%) rotate3d(0, -1, 0, 90deg);
    }

    50% {
        z-index: 0;
    }

    100% {
        z-index: 0;
        transform: translateX(0%) rotate3d(0, 0, 0, 0deg);
    }
}

@keyframes prior-left {
    0% {
        z-index: 0;
        transform: translateX(0%) rotate3d(0, 0, 0, 0deg);
    }

    50% {
        z-index: -1;
    }

    100% {
        z-index: -1;
        transform: translateX(100%) rotate3d(0, 1, 0, 90deg);
    }
}

/*Rotate Bottom*/

.focus.ani-bottom {
    transform-origin: top center;
    animation: focus-bottom var(--cube-rot-speed) ease forwards;
}

.prior.ani-bottom {
    transform-origin: bottom center;
    animation: prior-bottom var(--cube-rot-speed) ease forwards;
}

@keyframes focus-bottom {
    0% {
        z-index: -1;
        transform: translateY(100%) rotate3d(-1, 0, 0, 90deg);
    }

    50% {
        z-index: 0;
    }

    100% {
        z-index: 0;
        transform: translateY(0%) rotate3d(0, 0, 0, 0deg);
    }
}

@keyframes prior-bottom {
    0% {
        z-index: 0;
        transform: translateY(0%) rotate3d(0, 0, 0, 0deg);
    }

    50% {
        z-index: -1;
    }

    100% {
        z-index: -1;
        transform: translateY(-100%) rotate3d(1, 0, 0, 90deg);
    }
}

/*Rotate Right*/

.focus.ani-right {
    transform-origin: left center;
    animation: focus-right var(--cube-rot-speed) ease forwards;
}

.prior.ani-right {
    transform-origin: right center;
    animation: prior-right var(--cube-rot-speed) ease forwards;
}

@keyframes focus-right {
    0% {
        z-index: -1;
        transform: translateX(100%) rotate3d(0, 1, 0, 90deg);
    }

    50% {
        z-index: 0;
    }

    100% {
        z-index: 0;
        transform: translateX(0%) rotate3d(0, 0, 0, 90deg);
    }
}

@keyframes prior-right {
    0% {
        z-index: 0;
        transform: translateX(0%) rotate3d(0, 0, 0, 90deg);
    }

    50% {
        z-index: -1;
    }

    100% {
        z-index: -1;
        transform: translateX(-100%) rotate3d(0, -1, 0, 90deg);
    }
}

/*Rotate Back*/

.focus.ani-back {
    transform-origin: center bottom;
    animation: focus-back var(--cube-rot-speed-back) ease forwards;
}

.skip.ani-back {
    animation: skip-back var(--cube-rot-speed-back) ease forwards;
}

.prior.ani-back {
    transform-origin: center top;
    animation: prior-back var(--cube-rot-speed-back) ease forwards;
}

@keyframes focus-back {
    0% {
        visibility: hidden;
        z-index: -4;
        transform: translateY(-100%) rotate3d(1, 0, 0, 180deg);
    }

    33% {
        z-index: -2;
    }

    49.99% {
        visibility: hidden;
    }

    50% {
        visibility: visible;
        z-index: -1;
        transform: translateY(-100%) rotate3d(1, 0, 0, 90deg);
    }

    66% {
        z-index: 0;
    }

    100% {
        z-index: 0;
        transform: translateY(0%) rotate3d(0, 0, 0, 0deg);
    }
}

@keyframes skip-back {
    0% {
        transform-origin: center bottom;
        z-index: -1;
        transform: translateY(-100%) rotate3d(1, 0, 0, 90deg);
    }

    33% {
        z-index: 0;
    }

    50% {
        transform-origin: center bottom;
        transform: translateY(0%) rotate3d(0, 0, 0, 0deg);
    }

    50.01% {
        transform-origin: center top;
    }

    66% {
        z-index: 0;
    }

    100% {
        transform-origin: center top;
        transform: translateY(100%) rotate3d(-1, 0, 0, 90deg);
        z-index: 0;
    }
}

@keyframes prior-back {
    0% {
        visibility: visible;
        z-index: 0;
        transform: translateY(0%) rotate3d(0, 0, 0, 0deg);
    }

    33% {
        z-index: -1;
    }

    50% {
        visibility: visible;
        transform: translateY(100%) rotate3d(-1, 0, 0, 90deg);
    }

    50.01% {
        visibility: hidden;
    }

    66% {
        z-index: -2;
    }

    100% {
        visibility: hidden;
        transform: translateY(100%) rotate3d(-1, 0, 0, 90deg);
        z-index: -2;
    }
}